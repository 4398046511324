import { useState, useEffect } from 'react';

export default function TableOfContents(props) {
    const [links, setLinks] = useState('');
    const [titleLink, setTitleLink] = useState('');
    // console.log('TOC Loading:', props.loading);

    //generate links based off of content heading IDs. Interval is needed to allow content time to load/switch files when path changes.
    useEffect(() => {
        if (props.loading === false){
            let elapsed = 0;
            const checkContentInterval = setInterval(()=>{
                elapsed += 50;
                if (elapsed > 3000){
                    // console.log('No content found after three second, clearing interval');
                    clearInterval(checkContentInterval);
                }
                const contentNode = document.querySelector('.markdown-content');
                // console.log('contentNode:', contentNode);
                if(contentNode){
                    // console.log('elapsed:', elapsed);
                    const nodes = contentNode.querySelectorAll('h1, h2, h3, h4, h5, h6');
                    // console.log('nodes:', nodes);
                    let localLinks = [];
                    for (let i = 1; i < nodes.length; i++){
                        let marginAdjustment = '';
                        if (nodes[i].nodeName === 'H4'){ marginAdjustment = '20px'}
                        localLinks.push(<a key={`#${nodes[i].id}`} href={`#${nodes[i].id}`} style={{marginLeft: `${marginAdjustment}`}}>{nodes[i].textContent}</a>);    
                    }
                    setTitleLink(<a key={`#${nodes[0].id}`} href={`#${nodes[0].id}`}>{nodes[0].textContent}</a>)
                    setLinks(<>{localLinks}</>);
                    clearInterval(checkContentInterval);
                }
            }, 50);
        }
    }, [props.loading])

    return (
        <div className="table-of-contents" style={{display: `${props.tocToggle}`}}>
            <div className='toc-wrapper'>
                {links 
                    ? <nav className='toc-child'>
                        <h2>{titleLink}</h2>
                        {links}
                    </nav>
                    : ''
                }
            </div>
        </div>
    )
}
