import React from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { faBars, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Contact from './Contact.js';
// import { logOut } from '../../utils/AppUtils.js'


export default function Header(props) {
    let history = useHistory();
    const location = useLocation();
    const path = location.pathname.toLocaleLowerCase();
    // console.log('location.pathname:', location.pathname, 'path:', path, 'loggedIn:', props.loggedIn)

    // const login = <Link to='/Login'> <h3>Login</h3></Link>;
    // const logout = <button onClick={() => { logOut(history); }}>Sign Out</button>;
    // const profile = <Link to="/Profile"><h3>Profile</h3></Link>;
    // const register = <Link to='/Register'> <h3>Register</h3></Link>;
    console.log('sidebarToggle:', props.sidebarToggle, 'tocToggle:', props.tocToggle)

    const handleToggle = (buttonPressed) => {
        if (buttonPressed === 'sidebar'){
            if (props.sidebarToggle){ props.setSidebarToggle(''); }
            else{ props.setSidebarToggle('unset'); }
            props.setTocToggle('');
        }
        else{
            if (props.tocToggle){ props.setTocToggle(''); }
            else{ props.setTocToggle('unset'); }
            props.setSidebarToggle('');
        }
    }

    return (
        // idea- switch to !== path model, so that rather than manually specifying what goes where disable based off route 
        //and the rest will be shown in order
        <header>
            {props.loggedIn
                ? <> <Link to='/Dashboard' className="headerLogo"><div><h1>QUACKACADEMY</h1><h4>Alpha</h4></div></Link>
                    <nav>
                        {/* {!path.includes('/profile') && <>{profile}</>} */}
                        <Contact/>
                        {/* {logout} */}
                    </nav>
                </>
                : <>
                    <FontAwesomeIcon className="headerIcon" onClick={()=>{handleToggle('sidebar');}} style={{marginLeft:"17px"}}icon={faBars} />
                    <Link to='/' className="headerLogo"><div ><h1>QUACKACADEMY</h1><h4>Alpha</h4></div></Link>
                    <FontAwesomeIcon className="headerIcon" onClick={()=>{handleToggle('toc');}} style={{margin:"16px 17px 0 0"}}icon={faEllipsisH} />
                    <nav>
                        {!props.loading && !path.includes('/dashboard') && <>
                            {/* {!path.includes('/login') && <>{login}</>} */}
                            {/* {!path.includes('/register') && <>{register}</>} */}
                        </> }
                        <Contact/>
                    </nav>
                </>
            }
        </header>
    );
}