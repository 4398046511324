import { useEffect } from 'react';
import duckHead from '../../images/duck-head.jpg';

export default function FourOhFour() {
    useEffect(() => { document.title = 'QuackAcademy - 404'; });
    return (
        <div className='four-oh-four markdown-body'>
            <h1>Page not found!</h1>
            <img src={duckHead} alt={'Duck head'} title={'Image sourced from: https://unsplash.com/photos/eBFU7siwdKE'}/>
            <p>If you followed a link here it's likely missing because it doesn't exist yet.</p>
            <p>We're working hard on building out site content. Check back soon!</p>
        </div>
    )
}

// https://unsplash.com/photos/eBFU7siwdKE