import { useState, useEffect, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import FourOhFour from '../Global/FourOhFour';


export default function ContentView(props) {
    const location = useLocation();
    const path = location.pathname.toLocaleLowerCase();
    const [Content, setContent] = useState( lazy(async()=>{ return new Promise(); }));
    // console.log('path', path)

    useEffect(() => {
        props.setLoading(true);
        let filePath = '';
        const splitPath = path.split('/');
        for (let i = 0; i < splitPath.length; i++){
            if (i === splitPath.length -1){ filePath += `${splitPath[i].toLocaleLowerCase()}.js`; }
            else if (i > 0){ filePath += `${splitPath[i].toLocaleLowerCase()}/`; }
        }
        if (path === '/'){ filePath = 'landing_page'; }
        setContent(
            lazy(async() => {
                props.setLoading(false);
                return import(`../../content/${filePath}`)
                .catch(err=>{
                    console.log('Content lazy load from ./ error:', err);
                    props.setLoading(false);
                    return ({default: () => <FourOhFour />});
                })
            })
        );
    }, [path])

    return (
        <Suspense fallback={<div className='markdown-body' ><p style={{width: '800px'}}>Loading...</p></div>}>
            <Content />
        </Suspense>
    )
}