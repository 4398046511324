import { useState } from 'react';
import ContentView from './ContentView';
import Sidebar from './Sidebar/Sidebar.js';
import TableOfContents from './TableOfContents';

export default function ContentMain(props) {
    const [loading, setLoading] = useState(true);

    return (
        <div className="content-main">
            <Sidebar sidebarToggle={props.sidebarToggle}/>
            <ContentView setLoading={setLoading}/>
            <TableOfContents loading={loading} tocToggle={props.tocToggle}/>
        </div>
    )
}
