import { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { basicsLinks, frontendLinks, backendLinks, frontendDocLinks, backendDocLinks, generalGuideLinks, frontendGuideLinks, backendGuideLinks, templateLinks } from './Links';
import Folder from './Folder.js';

export default function Sidebar(props) {
    const location = useLocation();
    const path = location.pathname.toLowerCase();
    const [isOpenObj, setIsOpenObj] = useState({
        curriculum: false,
        basics: false,
        frontend: false,
        backend: false,
        documentation: false,
        docsBasics: false,
        docsFrontend: false,
        docsBackend: false,
        guides: false,
        guidesGeneral: false,
        guidesFrontend: false,
        guidesBackend: false,
    });

    console.log('sidebar sidebarToggle', props.sidebarToggle);

    const folderProps = { isOpenObj: isOpenObj, setIsOpenObj: setIsOpenObj, path: path }
    
    const curriculumChildren = <>
        <Folder name={'Basics'} children={basicsLinks} {...folderProps}/>
        <Folder name={'Frontend'} children={frontendLinks} {...folderProps}/>
        <Folder name={'Backend'} children={backendLinks} {...folderProps}/>
    </>

    const documentationChildren = <>
        <NavLink to='/docs/template'>Content Template</NavLink>
        <Folder name={'Frontend'} internalName={'docsFrontend'} children={frontendDocLinks} {...folderProps}/>
        {/* <Folder name={'Backend'} internalName={'docsBackend'} children={backendDocLinks} {...folderProps}/> */}
    </>

    const guideChildren = <>
        <Folder name={'General'} internalName={'guidesGeneral'}  children={generalGuideLinks} {...folderProps}/>
        <Folder name={'Frontend'} internalName={'guidesFrontend'}  children={frontendGuideLinks} {...folderProps}/>
        <Folder name={'Backend'} internalName={'guidesBackend'}  children={backendGuideLinks} {...folderProps}/>
    </>

    return (
        <div className="sidebar" style={{display: `${props.sidebarToggle}`}}>
            <div className='sidebar-wrapper'>
                <div className= 'sidebar-child'>
                    <Folder name={'Curriculum'} children={curriculumChildren} {...folderProps}/>
                    <Folder name={'Documentation'} children={documentationChildren} {...folderProps}/>
                    <Folder name={'Guides'} children={guideChildren} {...folderProps}/>
                    <Folder name={'Templates'} children={templateLinks} {...folderProps}/>
                </div>
            </div>
        </div>
    )
}