import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function Folder(props) {
    const [animation, setAnimation] = useState('');
    const [initial, setInitial] = useState(true);
    // console.log('props', props)
    // console.log('test', props.isOpenObj[props.internalName ? props.internalName : props.name.toLowerCase()])

    useEffect(() => {
        if(!initial){ setAnimation(props.isOpenObj[props.internalName ? props.internalName : props.name.toLowerCase()] ? `sidebar-rotate-up` : `sidebar-unRotate-up`); }
        else{ setInitial(false); }
    }, [props.isOpenObj[props.internalName ? props.internalName : props.name.toLowerCase()]])

    useEffect(() => {
        handlePathChange(props.isOpenObj, props.setIsOpenObj);
    }, [props.path])

    const handleChange = (name) => { props.setIsOpenObj({ ...props.isOpenObj, [name]: !props.isOpenObj[name] }); }

    const handlePathChange = (isOpenObj, setIsOpenObj) => {
        const updateObj = {};
        if (!isOpenObj.curriculum && props.path.includes('curriculum')){ updateObj.curriculum = true; }
        else if (!isOpenObj.basics && props.path.includes('curriculum/basics')){ updateObj.basics = true; }
        else if (!isOpenObj.frontend && props.path.includes('curriculum/frontend')){ updateObj.frontend = true; }
        else if (!isOpenObj.backend && props.path.includes('curriculum/backend')){ updateObj.backend = true; }
        else if (!isOpenObj.documentation && props.path.includes('docs/')){ updateObj.documentation = true; }
        else if (!isOpenObj.docsBasics && props.path.includes('docs/basics')){ updateObj.docsBasics = true; }
        else if (!isOpenObj.docsFrontend && props.path.includes('docs/frontend')){ updateObj.docsFrontend = true; }
        else if (!isOpenObj.docsBackend && props.path.includes('docs/backend')){ updateObj.docsBackend = true; }
        else if (!isOpenObj.guides && props.path.includes('guides/')){ updateObj.guides = true; }
        else if (!isOpenObj.guidesGeneral && props.path.includes('guides/general')){ updateObj.guidesGeneral = true; }
        else if (!isOpenObj.guidesFrontend && props.path.includes('guides/frontend')){ updateObj.guidesFrontend = true; }
        else if (!isOpenObj.guidesBackend && props.path.includes('guides/backend')){ updateObj.guidesBackend = true; }
        else if (!isOpenObj.templates && props.path.includes('templates')){ updateObj.templates = true; }
        setIsOpenObj({ ...isOpenObj, ...updateObj });
    }

    return(
        <div className='sidebar-folder'>
            <div onClick={()=>{ handleChange(props.internalName? props.internalName : props.name.toLowerCase()); }}>
                <h1>{props.name}</h1>
                <FontAwesomeIcon className={`${animation}`} icon={faChevronDown} />
            </div>
            {props.isOpenObj[props.internalName ? props.internalName : props.name.toLowerCase()]
                ? props.children
                : ''
            }
        </div>
    )
}