import { useState } from 'react';
import ContentMain from './components/content/ContentMain';
import Header from './components/Global/Header';

function App() {
  const [sidebarToggle, setSidebarToggle] = useState('');
  const [tocToggle, setTocToggle] = useState('');

  return (
    <>
      <Header sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} tocToggle={tocToggle} setTocToggle={setTocToggle}/>
      <ContentMain sidebarToggle={sidebarToggle} tocToggle={tocToggle} />
    </>
  );
}

export default App;