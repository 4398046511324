import { NavLink } from 'react-router-dom';

export const basicsLinks = <>
    <NavLink to='/curriculum/basics/01-intro'>[x] Course Introduction</NavLink>
    <NavLink to='/curriculum/basics/02-terminology'>General Terminology</NavLink>
    <NavLink to='/curriculum/basics/03-software_setup'>Software Installation/Setup</NavLink>
    <NavLink to='/curriculum/basics/04-git_basics'>Git Basics</NavLink>
    <NavLink to='/curriculum/basics/05-troubleshooting'>[X] Troubleshooting Basics</NavLink>
    {/* sub folder for html/css stuff? */}
    <NavLink to='/curriculum/basics/06-html_accessibility'>[X] HTML and Accessibility</NavLink>
    <NavLink to='/curriculum/basics/07-css_less'>[X] CSS/LESS</NavLink>
    <NavLink to='/curriculum/basics/08-css_flexbox'>[X] CSS: Flexbox</NavLink>
    <NavLink to='/curriculum/basics/09-responsive_design'>[X] Responsive Design</NavLink>
    {/* sub folder for javascript stuff? */}
    <NavLink to='/curriculum/basics/10-deployment'>[X] Deployment (Github Pages)</NavLink>
    <NavLink to='/curriculum/basics/11-javascript_basics'>[X] JavaScript Basics</NavLink>
    <NavLink to='/curriculum/basics/12-callbacks_closures'>[X] JavaScript 2: Callbacks and Closures</NavLink>
    <NavLink to='/curriculum/basics/13-dom_manipulation'>[X] JavaScript 3: Dom Manipulation</NavLink>
    <NavLink to='/curriculum/basics/14-event_listeners'>[X] JavaScript 4: Event Listeners</NavLink>
    <NavLink to='/curriculum/basics/15-consuming_rest_apis'>[X] JavaScript 5: Consuming REST APIs</NavLink>
    <NavLink to='/curriculum/basics/16-javascript_classes'>[X] Javascript Classes</NavLink>
    <NavLink to='/curriculum/basics/17-clean_code'>[X] Clean Code</NavLink>
    <NavLink to='/curriculum/basics/18-conclusion'>[X] Wrap up</NavLink>   
</>

export const frontendLinks = <>
    <NavLink to='/curriculum/frontend/01-react'>[X] Intro to React</NavLink>
    <NavLink to='/curriculum/frontend/02-components'>[X] Components and Prop Drilling</NavLink>
    <NavLink to='/curriculum/frontend/03-hooks'>[X] React Hooks</NavLink>
    <NavLink to='/curriculum/frontend/04-troubleshooting_react'>[X] Troubleshooting React</NavLink>
    <NavLink to='/curriculum/frontend/05-redux_vs_context'>[X] Context VS Redux</NavLink>
    <NavLink to='/curriculum/frontend/06-redux'>[X] Redux</NavLink>
    <NavLink to='/curriculum/frontend/07-deployment'>[X] Deployment (Vercel/Netlify/AWS)</NavLink>
    <NavLink to='/curriculum/frontend/08-git_flow'>[X] Git Flow 2</NavLink>
    <NavLink to='/curriculum/frontend/09-accessibility'>[X] Accessibility 2</NavLink>
    <NavLink to='/curriculum/frontend/10-conclusion'>[X] Wrap up</NavLink>
</>

export const backendLinks = <>
    <NavLink to='/curriculum/backend/01-node_express_intro'>[X] Node/Express Intro</NavLink>
    <NavLink to='/curriculum/backend/02-sql_intro'>[X] Databases/SQL intro</NavLink>
    <NavLink to='/curriculum/backend/03-routers_endpoints'>[X] Routers and Endpoints</NavLink>
    <NavLink to='/curriculum/backend/04-crud'>[X] Knex Intro</NavLink>
    <NavLink to='/curriculum/backend/05-troubleshooting_backend'>[X] Backend Troubleshooting</NavLink>
    <NavLink to='/curriculum/backend/06-authentication'>[X] Authentication/JSON Web Tokens</NavLink>
    <NavLink to='/curriculum/backend/07-middleware'>[X] Middleware</NavLink>
    <NavLink to='/curriculum/backend/08-testing'>[X] Testing with Jest/Supertest</NavLink>
    <NavLink to='/curriculum/backend/09-deployment'>[X] Cloud Deployment (Heroku/AWS)</NavLink>
    <NavLink to='/curriculum/backend/10-conclusion'>[X] Wrap up</NavLink>
</>


export const frontendDocLinks = <>
    <NavLink to='/docs/frontend/content_view'>ContentView.js</NavLink>
    <NavLink to='/docs/frontend/markdown_renderer'>MarkdownRenderer.js</NavLink>
</>
export const backendDocLinks = <>
    {/* <NavLink to='/curriculum/backend/node_express_intro'>[X] Node/Express Intro</NavLink> */}
</>


export const generalGuideLinks = <>
<NavLink to='/guides/general/shell_scripts'>[X] Shell Scripts for Web Development</NavLink>
<NavLink to='/guides/general/webhooks'>Webhooks</NavLink>
</>
export const frontendGuideLinks = <>
    {/* <NavLink to='/guides/frontend/'>[X] </NavLink> */}
</>
export const backendGuideLinks = <>
    <NavLink to='/guides/backend/insomnia'>API testing with Insomnia</NavLink>
</>


export const templateLinks = <>
    <NavLink to='/templates/express_starter'>Express Starter</NavLink>
</>